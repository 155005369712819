import Header from 'src/components/Header/Header';
import Corbin from 'src/static/images/corbin.png';
import './Home.css';


const Home = () => {
    return (
        <div className="Home">
            <Header/>
            <div className="home_main">
                <div className="home_container">

                    <div className="name_container">
                        <div className="name-1">Corbin</div>
                        <div className="name-2">Lutsch</div>
                    </div>

                    <div className="corbin_container">
                        <img className="corbin" src={Corbin} alt="" />
                    </div> 

                </div>
            </div>
        </div>
    );
}

export default Home;
