import Header from 'src/components/Header/Header';
import MailIcon from 'src/static/images/mail-logo.png';
import Modal from 'src/components/Modal/Modal';
import Loader from 'src/components/Loader/Loader';
import axios from 'axios';
import { useState } from 'react';
import './ContactPage.css';

const ContactPage = () => {
    const [from, setFrom] = useState('');
    const [fromError, setFromError] = useState('');

    const [subject, setSubject] = useState('');
    const [subjectError, setSubjectError] = useState('');

    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');

    const [isMessageSent, setIsMessageSent] = useState(false);
    const [isMessageFail, setIsMessageFail] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleCloseModal = () => {
        setIsMessageFail(false);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!hasErrors()){
            setIsLoading(true);
            axios.post('https://mail.corbinlutsch.com/email/send', {
                from: from,
                subject: subject,
                message: message
            }, 
            {
                headers: {
                    'type': 'mail'
                }
            }).catch(error => {
                setIsMessageFail(true);
            }).then(response => {
                if (response?.status === 200)
                    setIsMessageSent(true);
                else
                    setIsMessageFail(true);
            }).finally(() => {
                setIsLoading(false);
            });

        }        
    };

    const hasErrors = () => {
        let hasError: boolean = false;

        if (from !== ''){
            const emailRegex: RegExp = /^[\w-.]+@([\w-]+\.)+[\w-]*$/;
            const isValid: boolean = emailRegex.test(from);
            if (!isValid){
                hasError = true;
                setFromError('Please enter a valid email address');
            }
        } else {
            hasError = true;
            setFromError('Please enter an email');
        }
       
        if (subject === ''){
            hasError = true;
            setSubjectError('Please enter a subject');
        }

        if (message === ''){
            hasError = true;
            setMessageError('Please enter a message');
        }
    
        return hasError;
    };

    const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFrom(e.target.value);

        if (fromError === 'Please enter a valid email address'){
            const emailRegex: RegExp = /^[\w-.]+@([\w-]+\.)+[\w-]*$/;
            const isValid: boolean = emailRegex.test(e.target.value);
            isValid ? setFromError('') : setFromError('Please enter a valid email address');
        } else if (e.target.value !== ''){
            setFromError('');
        }

    };

    const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubject(e.target.value);
        if (e.target.value  !== '')
            setSubjectError('');
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
        if (e.target.value  !== '')
            setMessageError('');
    };

    const displayForm = () => {
        return (
            <div className="form">
            <form onSubmit={handleSubmit}>

                {/* From Input Box */}
                <div className={fromError === '' ? "form_input" : "form_input_error"}>
                    <div className="input_label">From:
                        <span className="star">*</span>
                    </div>
                    <input 
                    type="text" 
                    value={from} 
                    onChange={(e) => handleFromChange(e)}
                    placeholder="Your email address"
                    maxLength={50}
                     />
                </div>

                {/* Subject Input Box */}
                <div className={subjectError === '' ? "form_input" : "form_input_error"}>
                    <div className="input_label">Subject:
                        <span className="star">*</span>
                    </div>
                    <input 
                    type="text" 
                    value={subject} 
                    onChange={(e) => handleSubjectChange(e)} 
                    placeholder="Subject"
                    maxLength={50}
                    />
                </div>

                {/* Message Input Box */}
                <div className={messageError === '' ? "form_input" : "form_input_error"}>
                    <div className="input_label">Message:
                        <span className="star">*</span>
                    </div>
                    <textarea 
                    value={message} 
                    onChange={(e) => handleMessageChange(e)} 
                    placeholder="Max 3000 characters"
                    maxLength={3000}
                    />
                </div>

                {/* Error Messages */}
                <ul className="error_list">
                    {fromError !== '' ?
                    <li>
                        <div className="input_error">{fromError}</div>
                    </li> : null }

                    {subjectError !== '' ? 
                    <li>
                        <div className="input_error">{subjectError}</div>
                    </li> : null }

                    {messageError !== '' ?
                    <li>
                        <div className="input_error">{messageError}</div>
                    </li> : null }
                </ul>

                <button type="submit">Send</button>
            </form>
        </div>
        )
    }

    const displaySuccessMessage = () => {
        return (
            <div className="success_message">
                Your message has been successfully sent!
            </div> 
        )
    }

    return (
        <div className="ContactPage">
            <Header/>
                <div className="contact_main">
                    <div className="contact_title_text">
                        Let's Connect!
                    </div>

                    <div className="mail_container">
                        <img src={MailIcon} className="mail_icon" alt="" />
                    </div>
                    { isMessageFail ? <Modal onClose={handleCloseModal} /> : null }
                    { isLoading ? <Loader /> : 
                         isMessageSent ? displaySuccessMessage() : displayForm()
                    }
                </div>
        </div>
    );
}

export default ContactPage;
