import Header from 'src/components/Header/Header';
import Timeline from 'src/components/Timeline/Timeline';
import Carousel from 'src/components/Carousel/Carousel';
import './About.css';

const About = () => {
    return (
        <div className="About">
            <Header />
            <div className="experience_container"> 
                <div className="title_text_extra_padding">
                    Experience 
                </div>
            </div>

            <Timeline />
      
            <div className="about_carousel" id="about_carousel">
                <Carousel />
            </div>
        </div>
    );
}

export default About;
