import { useEffect, useState } from 'react';
import NIULogo from 'src/static/images/niu-logo.png';
import DiscoverLogo from 'src/static/images/discover-logo.png';
import DoughBrothersLogo from 'src/static/images/doughbrothers.jpg';
import './Timeline.css';

type Opacities = {
    [key: string]: number;
}

const Timeline = () => {
    const [opacities, setOpacities] = useState<Opacities>({});

    const handleScroll = () => {
        const elements = document.querySelectorAll('.timeline_container');

        const newOpacities: Opacities = {};
        elements.forEach((element) => {
            const id = element.id;
            const { top } = element.getBoundingClientRect();

            const carouselHeight = 300;
            const windowHeight = window.innerHeight - carouselHeight;
    
            const timelineContainerHalfHeight = 0
            const visibleTop = Math.max(0, Math.min(windowHeight, windowHeight - (top - timelineContainerHalfHeight)));

            const percentageVisible = visibleTop * 2 / windowHeight;
            const opacity = percentageVisible;

            newOpacities[id] = opacity;
        })
        setOpacities(newOpacities);
    }

    useEffect(() =>{ 
        window.addEventListener('scroll', handleScroll);
        
        handleScroll()

        return () => {
            window.removeEventListener('scroll', handleScroll);
          };     
    }, [])

    return (
        <div className="timeline">
            <div className="timeline_container" id="timeline_container_1">
                <div className="timeline_content_year"> 
                    <div className="timeline_year">Jun. 2012</div>
                    <div className="timeline_year">Jan. 2019</div>
                </div>
                <div className="content_line">
                    <div className="circle"/>
                    <div className="vertical_line_first"/>
                </div>
                <div className="timeline_content">
                    <div className="timeline_label">Assistant Manager</div>
                    <div className="timeline_logo_container">
                        <img className="db_logo_small" src={DoughBrothersLogo} alt="" />
                    </div>
                </div>
            </div>

            <div className="timeline_container" id="timeline_container_2" style={{opacity: opacities['timeline_container_2']}}>
                <div className="timeline_content">
                    <div className="timeline_label">Bachelor of Science in C.S.</div>
                    <div className="timeline_logo_container">
                        <img className="niu_logo_small" src={NIULogo} alt="" />
                    </div>
                </div>
                <div className="content_line">
                    <div className="circle"/>
                    <div className="vertical_line"/>
                </div>
                <div className="timeline_content_year">
                    <div className="timeline_year">Sep. 2017</div>
                    <div className="timeline_year">Aug. 2019</div>
                </div>
            </div>

            <div className="timeline_container" id="timeline_container_3" style={{opacity: opacities['timeline_container_3']}}>
                <div className="timeline_content_year">
                    <div className="timeline_year">May. 2019</div>
                    <div className="timeline_year">Aug. 2019</div>
                </div>
                <div className="content_line">
                    <div className="circle"/>
                    <div className="vertical_line"/>
                </div>
                <div className="timeline_content">
                    <div className="timeline_label">BT Campus Innovator</div>
                    <div className="timeline_logo_container">
                        <img className="discover_logo_small" src={DiscoverLogo} alt="" />
                    </div>
                </div>
            </div>

            <div className="timeline_container" id="timeline_container_4" style={{opacity: opacities['timeline_container_4']}} >
                <div className="timeline_content">
                    <div className="timeline_label">Associate Software Developer</div>
                    <div className="timeline_logo_container">
                        <img className="discover_logo_small" src={DiscoverLogo} alt="" />
                    </div>
                </div>
                <div className="content_line">
                    <div className="circle"/>
                    <div className="vertical_line"/>
                </div>
                <div className="timeline_content_year">
                    <div className="timeline_year">Sep. 2019</div>
                    <div className="timeline_year">Jul. 2021</div>
                </div>
            </div>

            <div className="timeline_container" id="timeline_container_5" style={{opacity: opacities['timeline_container_5']}}>
                <div className="timeline_content_year">
                    <div className="timeline_year">Jul. 2021</div>
                    <div className="timeline_year">Aug. 2022</div>
                </div>

                <div className="content_line">
                    <div className="circle"/>
                    <div className="vertical_line"/>
                </div>

                <div className="timeline_content">
                    <div className="timeline_label">Senior Associate Software Developer</div>
                    <div className="timeline_logo_container">
                        <img className="discover_logo_small" src={DiscoverLogo} alt="" />
                    </div>
                </div>
            </div>

            <div className="timeline_container" id="timeline_container_6" style={{opacity: opacities['timeline_container_6']}}>
                <div className="timeline_content">
                    <div className="timeline_label">Application Engineer</div>
                    <div className="timeline_logo_container">
                        <img className="discover_logo_small" src={DiscoverLogo} alt="" />
                    </div>
                </div>
                <div className="content_line">
                    <div className="circle"/>
                    <div className="vertical_line_last"/>
                </div>
                <div className="timeline_content_year">
                    <div className="timeline_year">Aug. 2022</div>
                    <div className="timeline_year">-</div>
                </div>
            </div>

            <div className="timeline_bottom_container">     
                <div className="resume_container">
                    * To learn more about my experience, please see my 
                    <a className="resume" target="_blank" href="Corbin Lutsch Resume.pdf">resume</a>
                </div>
            </div>

        </div>
    )
}

export default Timeline;