import Adobe from 'src/static/images/logos/logo-0.png';
import Android from 'src/static/images/logos/logo-1.png';
import Angular from 'src/static/images/logos/logo-2.png';
import Apache from 'src/static/images/logos/logo-3.png';
import AppDynamics from 'src/static/images/logos/logo-4.png';
import AWS from 'src/static/images/logos/logo-5.png';
import Bash from 'src/static/images/logos/logo-6.png';
import CPlusPlus from 'src/static/images/logos/logo-7.png';
import GraphQL from 'src/static/images/logos/logo-8.png';
import CSharp from 'src/static/images/logos/logo-9.png';
import CSS from 'src/static/images/logos/logo-10.png';
import Docker from 'src/static/images/logos/logo-11.png';
import Github from 'src/static/images/logos/logo-12.png';
import Gradle from 'src/static/images/logos/logo-13.png';
import Spring from 'src/static/images/logos/logo-14.png';
import Html5 from 'src/static/images/logos/logo-15.png';
import IOS from 'src/static/images/logos/logo-16.png';
import Java from 'src/static/images/logos/logo-17.png';
import Jmeter from 'src/static/images/logos/logo-18.png';
import Jenkins from 'src/static/images/logos/logo-19.png';
import Jira from 'src/static/images/logos/logo-20.png';
import Kibana from 'src/static/images/logos/logo-21.png';
import Kubernetees from 'src/static/images/logos/logo-22.png';
import Websphere from 'src/static/images/logos/logo-23.png';
import MariaDB from 'src/static/images/logos/logo-24.png';
import Nginx from 'src/static/images/logos/logo-25.png';
import oAuth from 'src/static/images/logos/logo-26.png';
import Openshift from 'src/static/images/logos/logo-27.png';
import cloudfoundry from 'src/static/images/logos/logo-28.png';
import php from 'src/static/images/logos/logo-29.png';
import react from 'src/static/images/logos/logo-30.png';
import redis from 'src/static/images/logos/logo-31.png';
import redux from 'src/static/images/logos/logo-32.png';
import './Carousel.css';

const carousel1 = () => {
    return (
        <>
            <div className="carousel_logo_container">
                <img src={Adobe} alt="" width={250} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={Android} alt="" width={150} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={Angular} alt="" width={200} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={Apache} alt="" width={250} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={AppDynamics} alt="" width={250} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={AWS} alt="" width={200} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={Bash}  alt="" width={250} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={CPlusPlus}  alt="" width={100} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={GraphQL}  alt="" width={150} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={CSharp}  alt="" width={100} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={Websphere}  alt="" width={250} height={30} />
            </div>
        </>
    )
}

const carousel2 = () => {
    return (
        <>
            <div className="carousel_logo_container_reverse">
                <img src={CSS} alt="" width={100} height={30} />
            </div>
            <div className="carousel_logo_container_reverse">
                <img src={Docker} alt="" width={200} height={30} />
            </div>
            <div className="carousel_logo_container_reverse">
                <img src={Github} alt="" width={100} height={30} />
            </div>
            <div className="carousel_logo_container_reverse">
                <img src={Gradle} alt="" width={250} height={30} />
            </div>
            <div className="carousel_logo_container_reverse">
                <img src={Html5} alt="" width={150} height={30} />
            </div>
            <div className="carousel_logo_container_reverse">
                <img src={IOS} alt="" width={150} height={30} style={{paddingLeft: '20px'}}/>
            </div>
            <div className="carousel_logo_container_reverse">
                <img src={Java}  alt="" width={100} height={30} />
            </div>
            <div className="carousel_logo_container_reverse">
                <img src={Jmeter}  alt="" width={250} height={30} />
            </div>
            <div className="carousel_logo_container_reverse">
                <img src={Jenkins}  alt="" width={250} height={30} />
            </div>
            <div className="carousel_logo_container_reverse">
                <img src={Jira}  alt="" width={250} height={30} />
            </div>
            <div className="carousel_logo_container_reverse">
                <img src={Spring}  alt="" width={200} height={30} />
            </div>
        </>
    )
}

const carousel3 = () => {
    return (
        <>
            <div className="carousel_logo_container">
                <img src={Kibana} alt="" width={200} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={Kubernetees} alt="" width={100} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={MariaDB} alt="" width={250} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={Nginx} alt="" width={200} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={oAuth}  alt="" width={100} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={Openshift}  alt="" width={100} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={cloudfoundry}  alt="" width={200} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={php}  alt="" width={100} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={react}  alt="" width={100} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={redis}  alt="" width={200} height={30} />
            </div>
            <div className="carousel_logo_container">
                <img src={redux}  alt="" width={150} height={30} />
            </div>
        </>
    )
}

const Carousel = () => {
    return (
        <div className="carousel_container">
            <div className="carousel">
                <div className="carousel_slider_1">
                    {carousel1()}
                    {carousel1()}
                </div>
            </div>
            <div className="carousel">
                <div className="carousel_slider_reverse">
                    {carousel2()}
                    {carousel2()}
                </div>
            </div>
            <div className="carousel">
                <div className="carousel_slider_2">
                    {carousel3()}
                    {carousel3()}
                </div>
            </div>
        </div>
    )
} 

export default Carousel;