import Header from 'src/components/Header/Header';
import Footer from 'src/components/Footer/Footer';
import portalLegacy from 'src/static/images/portal-large-view-legacy.jpg';
import portalLegacyMobile from 'src/static/images/portal-small-view-legacy.jpg';
import portal from 'src/static/images/portal-large-view.png'
import portalMobile from 'src/static/images/portal-small-view.png'
import MobileApp1 from 'src/static/images/mobile-portal-1.png';
import MobileApp2 from 'src/static/images/mobile-portal-2.png';
import MobileApp3 from 'src/static/images/mobile-portal-3.png';
import MobileApp4 from 'src/static/images/quickview.png';
import PartsSolution1 from 'src/static/images/parts-solution-1.png';
import PartsSolution2 from 'src/static/images/parts-solution-2.png';
import PartsSolution3 from 'src/static/images/parts-solution-3.png';
import PartsSolution4 from 'src/static/images/parts-solution-4.png';
import PartsSolution5 from 'src/static/images/parts-solution-5.png';
import PartsSolution6 from 'src/static/images/parts-solution-6.png';
import GraphingCalculator1 from 'src/static/images/graphing-calculator-1.png';
import GraphingCalculator2 from 'src/static/images/graphing-calculator-2.png';
import GraphingCalculator3 from 'src/static/images/graphing-calculator-3.png';
import GraphingCalculator4 from 'src/static/images/graphing-calculator-4.png';
import Compiler1 from 'src/static/images/compiler-1.png';
import Compiler2 from 'src/static/images/compiler-2.png';
import './Projects.css';

const Projects = () => {
    return (
        <div className="Projects">
            <div className="projects_main">
                <Header/>
                <div className="title_text additional_padding_top">
                    Portal Pages
                </div>

                {/* Customer Portal Legacy */}
                <div className="main_body_1">
                    <div className="portal_mobile_container">
                        <img className="portal_mobile_legacy" src={portalLegacyMobile} alt="" />
                        <div className="arrow_container_1">
                            <div className="down_arrow" />
                        </div>
                    </div>
                    <div className="portal_web_container">
                        <img className="portal_web" src={portalLegacy} alt="" />
                        <div className="arrow_container_2">
                            <div className="down_arrow" />
                        </div>
                    </div>
                </div>

                {/* Customer Portal  */}
                <div className="main_body_2">
                    <div className="portal_mobile_container">
                        <img className="portal_mobile" src={portalMobile} alt="" />
                    </div>
                    <div className="portal_web_container">
                        <img className="portal_web" src={portal} alt="" />
                    </div>
                </div>

                <div className="disclaimer">
                    <b>*Disclaimer:</b> I do not claim to own any of Discover's content or property. 
                    The images displayed represent some of the work I did as 
                    a software engineer and are for educational purposes only. 
                </div>

                <div className="title_text_additional_top_padding">
                    Mobile Application
                </div>

                {/* Mobile Application */}
                <div className="mobile_body">
                    <div className="mobile_app_screenshot_container">
                        <img className="mobile_app_screenshot" src={MobileApp1} alt="" />
                    </div>

                    <div className="mobile_app_screenshot_container">
                        <img className="mobile_app_screenshot" src={MobileApp3} alt="" />
                    </div>

                    <div className="mobile_app_screenshot_container">
                        <img className="mobile_app_screenshot" src={MobileApp2} alt="" />
                    </div>

                    <div className="mobile_app_screenshot_container">
                        <img className="mobile_app_screenshot" src={MobileApp4} alt="" />
                    </div>
                </div>

                {/*  International Parts Solution*/}
                <div className="title_text_additional_top_padding">
                    International Parts Solution 
                </div>

                <div className="small_text">
                    Below was a project I built in college. It uses php and mysql as the backend 
                    and apache to host the fontend. 
                    You can create parts, vendor accounts, custom quotes, upload images, and generate a wide
                    variety of reports. 
                </div>

                <div className="main_body_2">
                    <div className="parts_solution_container">
                        <img className="parts_solution" src={PartsSolution1} alt="" />
                    </div>
                    <div className="parts_solution_container">
                        <img className="parts_solution" src={PartsSolution2} alt="" />
                    </div>
                </div>
                <div className="main_body_2">
                    <div className="parts_solution_container">
                        <img className="parts_solution" src={PartsSolution3} alt="" />
                    </div>
                    <div className="parts_solution_container">
                        <img className="parts_solution" src={PartsSolution4} alt="" />
                    </div>
                </div>
                <div className="main_body_2">
                    <div className="parts_solution_container">
                        <img className="parts_solution" src={PartsSolution5} alt="" />
                    </div>
                    <div className="parts_solution_container">
                        <img className="parts_solution" src={PartsSolution6} alt="" />
                    </div>
                </div>

                {/* Graphing Calculator */}
                <div className="title_text" style={{paddingTop: '50px'}}>
                    Graphing Calculator 
                </div>

                <div className="small_text" style={{paddingBottom: '20px'}}>
                    I built a graphing calculator using C# and .NET      
                </div>

                <div className="main_body_2">
                    <div className="graphing_calculator_container">
                        <img className="graphing_calculator" src={GraphingCalculator1} alt="" />
                    </div>
                    <div className="graphing_calculator_container">
                        <img className="graphing_calculator" src={GraphingCalculator2} alt="" />
                    </div>
                </div>

                <div className="main_body_2">
                    <div className="graphing_calculator_container">
                        <img className="graphing_calculator" src={GraphingCalculator3} alt="" />
                    </div>
                    <div className="graphing_calculator_container">
                        <img className="graphing_calculator" src={GraphingCalculator4} alt="" />
                    </div>
                </div>

                {/* Compiler */}
                <div className="title_text" style={{paddingTop: '50px'}}>
                    Compiler 
                </div>
                <div className="small_text">
                    Below is a compiler I built using Intel Assembly language and binary trees. 
                    It uses a lexical analyzer to read input characters and executes machine-level commands.
                    It is capable of variable declaration, assignment statements, printing output,
                    arithmetic, if-else statements, while loops, and much more!
                    Out of all of my projects, this takes the cake as the toughest one.  
                </div>

                <div className="main_body_2">
                    <div className="compiler_container">
                        <img className="compiler" src={Compiler1} alt="" />
                    </div>
                    <div className="compiler_container">
                        <img className="compiler" src={Compiler2} alt="" />
                    </div>
                </div>


                {/* More Coming Soon... */}
                <div className="main_body_3">
                    <div className="coming_soon_container">
                        <div className="coming_soon"> 
                            More coming soon...
                        </div>
                    </div>
                </div>                
            </div>
            <Footer/>
        </div>
    );
}

export default Projects;
