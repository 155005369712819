import './App.css';
import Home from 'src/pages/Home/Home';
import About from 'src/pages/About/About';
import Projects from './pages/Projects/Projects';
import ContactPage from './pages/ContactPage/ContactPage';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/projects" element={<Projects/>}></Route>
          <Route path="/about" element={<About/>}></Route>
          <Route path="/contact" element={<ContactPage/>}></Route>
        </Routes>
      </div>
  </Router>
  );
}

export default App;
