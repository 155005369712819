import FireGIF from 'src/static/images/fire.gif';
import { useState } from 'react';
import './Modal.css';

const Modal = (props: any) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleClose = () => {
        setIsOpen(false);
        props.onClose(); 
      };

    const displayModal = () => {
        return (        
        <div className="modal">
            <div className="modal_content">
                <button className="modal_close" onClick={e => handleClose()}>x</button>
                <h2 className="modal_text">Something went wrong...</h2>
                <img className="fire_gif" src={FireGIF} alt="" />
                <div className="modal_text">In the meantime, please contact me via 
                    <a className="linkedin" href="https://www.linkedin.com/in/corbinlutsch/" target="_blank" rel="noreferrer">
                        LinkedIn
                    </a>
                </div>
            </div>
        </div>
        )
    }

  
    return isOpen ? displayModal() : null;
}

export default Modal;