import './Header.css';

const Header = () => {
    return (
        <div className="header">
            <a className="header_links" href="/">Home</a>
            <a className="header_links" href="/projects">Projects</a>
            <a className="header_links" href="/about">About</a>
            <a className="header_links" href="/contact">Contact</a>
        </div>
    )
}

export default Header;